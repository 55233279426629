@font-face {
  font-family: JetBrains Mono;
  font-weight: 400;
  font-style: normal;
  src: url("JetBrainsMono-Regular.74c02fbc.woff2") format("woff2");
}

@keyframes spring-in {
  0% {
    opacity: 1;
    transform: translateY(-100%);
  }

  16% {
    transform: translateY(32.27%);
  }

  28% {
    transform: translateY(-13.12%);
  }

  44% {
    transform: translateY(4.63001%);
  }

  59% {
    transform: translateY(-1.64%);
  }

  73% {
    transform: translateY(.580001%);
  }

  88% {
    transform: translateY(-.199997%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

body {
  color: #98c1d9;
  background-color: #293241;
  margin: 0;
  font-family: JetBrains Mono, monospace;
  font-style: italic;
  font-weight: 400;
  overflow: hidden;
}

canvas {
  position: absolute;
  top: 0;
}

main {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3vh;
  display: flex;
}

main > * {
  text-align: center;
  margin: 0;
}

.title-anim {
  color: #e0fbfc;
  font-size: 20vh;
  animation: spring-in 1s .4s forwards;
}

h2 {
  font-size: 5vh;
}

p {
  color: #3d5a80;
  margin: 1rem;
  font-size: 1.5vh;
  position: absolute;
  bottom: 0;
}

p a {
  color: #3d5a80;
}

/*# sourceMappingURL=index.7c352fbf.css.map */
